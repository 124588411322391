import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../../../../../components/Button'
import CheckBox from '../../../../../components/CheckBox'
import isServerSide from '../../../../../modules/is-server-side'

const Wrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 15px 44px 15px 43px;
  margin-bottom: 7px;
  outline: rgb(205, 205, 205) solid 0.5px;
  margin-left: 1px;
  margin-top: 6px;
`

const CrossDiv = styled.div`
  position: absolute;
  right: 60px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
  width: 18px;
  top: 100px;

  margin-left: auto;
  :after {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(-4px) rotate(135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  :before {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(5px) rotate(-135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  cursor: pointer;
  @media (max-width: 1080px) {
    top: 110px;
    right: 38px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: 80px;
    right: 14px;
  }
`

const BranchChoiceContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const BranchChoiceHolder = styled.div`
  margin-left: auto;
  margin-right: auto;

  width: 298px;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif;
  padding-left: 4px;

  padding-top: 14px;
  @media (max-width: 1080px) {
    padding-left: 6px;
    padding-top: 15px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: 0px;
    padding-top: 11px;
    margin-left: -6px;
  }
`

const StyledButton = styled(Button)``

const ChoiceHolderDiv = styled.div`
  height: 45px;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 40px;
  }
`

const StyledCheckbox = styled(CheckBox)`
  position: relative;

  left: 15px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    left: 3px;
  }
`

const DescriptionBox = styled.div`
  font-family: 'Splus Icon', 'Fira Sans', sans-serif;
  font-weight: 700;
  padding: 20px 20px 0px;
  @media (max-width: 1080px) {
    padding: 20px 40px 0px 0px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 20px 0 0px;
    font-size: 14px;
    margin-left: -6px;
  }
`

const DeleteAllButton = styled.div`
  color: #d41d24;
  cursor: pointer;

  margin-bottom: 10px;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif;
  padding: 20px;
  padding-bottom: 0px;
  &:hover {
    text-decoration: underline;
  }
  padding-left: 21px;
  @media (max-width: 1080px) {
    margin-left: -20px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 0px;
    padding-left: 7px;
    margin-left: -11px;
  }

  width: 200px;
`

const SaveAllButton = styled(Button)`
  margin-left: auto;
  margin-top: 30px;
  margin-right: -22px;
  min-width: 110px;

  @media (max-width: 1080px) {
    margin-left: auto;
    margin-top: 11px;
    margin-right: 0px;
    min-width: 110px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 12px;
    margin-right: -4px;
    min-width: 80px;
  }
`

const ChoiceField = styled.div`
  margin: 5px;

  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1080px) {
    margin-left: -16px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    max-height: 220px;
    overflow: auto;
    overflow-x: hidden;
    margin-left: -9px;
  }
`

const ButtonField = styled.div`
  display: flex;
`
const BranchChoice = ({ branches, handleClose, run, ...props }) => {
  const [stateBranchChoice, setBranchChoice] = useState({
    jobChoice: JSON.parse(window.localStorage.getItem('jobpreferences'))
      ? JSON.parse(window.localStorage.getItem('jobpreferences'))
      : [],
  })

  function handleClosePlaceHolder() {
    handleClose(run)
  }

  function branchCheckbox(value) {
    let jobs = stateBranchChoice.jobChoice
    if (jobs.includes(value)) {
      jobs = jobs.filter(function (item) {
        return item !== value
      })
    } else {
      jobs.push(value)
    }
    setBranchChoice({ jobChoice: jobs })
  }

  function deleteAll() {
    setBranchChoice({ jobChoice: [] })
  }

  function saveState(handleClose) {
    let branchChoice = window.localStorage.getItem('jobpreferences')
    let jobs = stateBranchChoice.jobChoice
    if (!jobs.length > 0 && branchChoice) {
      window.localStorage.removeItem('jobpreferences')
    }
    if (jobs.length > 0) {
      window.localStorage.setItem('jobpreferences', JSON.stringify(jobs))
    }

    SLC('pageEvent', 'jobads-category', 'config', null, null, jobs)

    handleClose(run)
  }

  return (
    <Wrapper {...props}>
      <DescriptionBox>
        <CrossDiv onClick={handleClosePlaceHolder}></CrossDiv>
        Bitte teilen Sie uns mit, welche Bereiche Sie interessieren würden. Mit Ihrer Auswahl versuchen wir Ihnen die
        passendsten Stellenangebote auszuspielen. Diese Auswahl können Sie jederzeit verändern oder löschen.
      </DescriptionBox>
      <ChoiceField>
        {branches.map(function (branch) {
          return (
            <ChoiceHolderDiv
              onClick={() =>
                branchCheckbox(process.env.RAZZLE_ENVIRONMENT == 'production' ? branch.value : branch.value_stg)
              }
            >
              <StyledCheckbox
                checked={stateBranchChoice.jobChoice.includes(
                  process.env.RAZZLE_ENVIRONMENT == 'production' ? branch.value : branch.value_stg,
                )}
              ></StyledCheckbox>
              <BranchChoiceHolder>{branch.label}</BranchChoiceHolder>
            </ChoiceHolderDiv>
          )
        })}
      </ChoiceField>
      <ButtonField>
        <DeleteAllButton onClick={() => deleteAll()}> >> &nbsp; &nbsp; Auswahl aufheben</DeleteAllButton>
        <SaveAllButton onClick={() => saveState(handleClose)}>Speichern</SaveAllButton>
      </ButtonField>
    </Wrapper>
  )
}
BranchChoice.propTypes = {}
BranchChoice.defaultProps = {}
export default BranchChoice
